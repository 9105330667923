<template>
	<!-- tomado de https://codepen.io/AissaDiafi/pen/zgmPab?editors=1100 -->

	<div class="ui-loading-line">
		<span></span>
        <span></span>
        <span></span>
        <span></span>
	</div>
</template>

<script>
export default {
	name: 'ui-loading-line'
}
</script>

<style lang="scss" scoped>
.ui-loading-line {
    width: 72px;
    height: 30px;
    position: relative;
    // background: red;
    // margin: 100px auto;

	span {
		top: 15px;
	    left: 10px;
	    width: 10px;
	    height: 10px;
	    position: absolute;
	    border-radius: 50%;
	    display: inline-block;
	    background-color: #1E88E5;
	    animation: snaking 1.5s linear infinite;
	}

	span:nth-of-type(2) {
	    left: 25px;
	    animation-delay: .2s;
	}

	span:nth-of-type(3) {
	    left: 40px;
	    animation-delay: .4s;
	}

	span:nth-of-type(4) {
	    left: 55px;
	    animation-delay: .6s;
	}
}

@keyframes snaking {
    0%, 100% {
        transform: translateY(0);
    }

    56% {
        transform: translateY(-10px);
        box-shadow: 0 30px 15px rgba(0, 0, 0, .1);
    }
}
</style>

