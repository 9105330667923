export default function(httpClient) {

    return {
    	createPost(authorId, postData) {
            return httpClient.post('/people/'+authorId+'/posts/sent', postData);
        },

        generateAudience(students) {
            return httpClient.get(`/v3/person/relation/members?students=${students}`);
        }
    }

}